import React from "react";
import Link from "next/link";

import BackButton from "../components/backbutton";

export default function FourOFour() {
  return (
    <div className="h-screen w-full">
      <div
        className={`top-0 left-0 fixed h-auto w-full items-center text-sm flex z-10`}
      >
        <div
          className={`flex items-center w-full bg-gunmetal-darker pt-4 pb-3 top-0 z-10`}
        >
          <div className="inline-block col-span-2 text-left">
            <Link href={`/workspace`}>
              <BackButton />
            </Link>
            <img
              className="ml-12 md:ml-20 h-8 object-fit mt-1"
              src="/logo.png"
            />
          </div>
        </div>
      </div>
      <div className="h-full w-full flex items-center justify-center text-lg">
        Page not found!
      </div>
    </div>
  );
}
