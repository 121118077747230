import React from "react";

export default function BackButton(props) {
  return (
    <button
      onClick={(e) => props.onClick(e)}
      className="btn-back text-sm fixed left-4 top-4 focus:outline-none text-white opacity-90 z-50 hover:opacity-100 bg-arrow-left h-10 bg-auto bg-no-repeat bg-left p-2 pl-3 md:pl-6"
    >
      <span className={`${props.hideText ? "" : "md:block"} hidden`}>Back</span>
    </button>
  );
}
